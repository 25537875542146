.submit-button {
    background-color: #0570de; /* Couleur de fond par défaut */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:disabled,
  .submit-button.disabled {
    background-color: #cccccc; /* Couleur de fond grisée pour le bouton désactivé */
    color: #666666; /* Couleur du texte grisé */
    cursor: not-allowed;
  }
  