/* Global */
@tailwind base;
@tailwind components;
@tailwind utilities;


a {
    text-decoration: none;
    color: #270D60;
}

.container {
    margin-left: 130px;
}

.item-container {
    margin-right: 50px;
    margin-left: 50px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
    color: #270D60;
}

/* * {
  outline: 1px solid red;
} */

/* RESPONSIVE */

/* Desktop */
@media only screen and (max-width: 1440px) {
  .container-chart{
    width: 45%!important;
  }
  .title-chart{
    font-size: 15px!important;
  }
}

/* Laptop */
@media only screen and (max-width: 1024px) {
  .container-chart{
    width: 90%!important;
  }
  .all-card-container{
    margin: auto;
  }
}

/* Tablet */
  
@media only screen and (max-width: 768px) {
    .content-top {
        display: block !important;
        flex-direction: column;
        gap: 10px;
    }

    .container-chart {
        width: 95% !important;
    }

    .title-chart {
        position: initial !important;
    }

    .refresh-icon-responsive {
        display: block !important;
    }

    .refresh-icon {
        display: none;
    }

    .stats-date-container {
        display: flex;
        flex-direction: column;
    }

    .stat-date-title {
        display: flex;
        align-items: stretch;
        gap: 20px;
        margin: auto;
    }

    .left-date-link-container {
        align-self: center;
        margin-top: 15px;
    }

    .header-container {
        display: none !important;
    }

    .carrousel-phone-container {
        display: block !important;
    }

}

/* Phone */
@media only screen and (max-width: 426px) {
    .item-container {
        margin-left: 15px;
        margin-right: 15px;
    }
    .navBar-phone{
        display: block!important;
    }


    .container {
        margin-left: 0;
    }
    .navBarConainer{
        display: none;
    }

    .container-chart {
        width: 90% !important;
        max-height: 400px;
        margin-top: 10px;
    }
    .phone-navbar{
      display: block!important;
    }

    /* .test{
      max-height: 300px;
      padding-bottom: 35px;
    } */
    .skip-section-container {
        width: 100% !important;
    }

    .title-chart {
        margin-bottom: 20px;
    }

    .stats-date-container {
        font-size: 9px;
    }

    .stat-date-title h3 {
        font-size: 11px !important;
        margin-top: 5px !important;

    }
    .navBarConainer{
      display: none;
    }

}

/* COMPONENTS */

/* HEADER */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 50px;
    margin-left: 50px;
    margin-top: 15px;
}

.header-container h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    color: #270D60;
}

.header-profil-container h3 {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    
}

.initial-profil h3 {
    margin-left: 8px;
    font-weight: 700;
    color: #270D60;   
}



/* NAVBAR */

.navBarConainer {
    width: 120px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
    border: #270D60;
}

.itemNavBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    height: 70px;
}

.activeItemNavBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    background-color: #4F12B81C;
    height: 70px;
    border-right: 5px solid #4F12B8;
    width: 100%;
}

.logoItem {
    margin-bottom: 10px;
}

.pending-payment-banner {
    background-color: #ff4d4d; /* Rouge */
    color: white;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.titleItemNavBar {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
    margin: 0;
    color: #270D60;
}

.logoTeamupp {
    width: 100px;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: 10px;
}

.phone-navbar{
  display: none;
}

.phone-navbar-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #FBF7FF;
  padding: 13px 10px;
  box-shadow: 2px 13px 15px -7px #000000;
}
.phone-navbar-container img{
  max-width: 100px;
  max-height: 100px;
}

/* HomeScreen */

.stats-date-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 12px 13px;
}

.refresh-icon-responsive {
    display: none;
}

.selected-link {
    background-color: #270D60;
    color: #ffff;
    border-radius: 20px;
    padding: 12px 13px;
}

.stat-date-title h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    color: #270D60;
    margin-left: 20px;
    margin-top: 10px;
}


.date-link-container {
    background-color: #FBF7FF;
    padding: 13px 0px;
    border-radius: 50px;
}

.date-link-container-selected {
    background-color: #FBF7FF;
    padding: 13px 0px;
    border-radius: 50px;
}

.date-link-container-selected a {
    width: 100%;
}

.link {
    margin-right: 10px;
    padding: 13px 10px;
}

.date-link-container a {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 10px;
}

.date-link-container a:hover {
    color: #4F12B8;
}

.ellipse-white-top {
    background-color: #FBF7FF;
    padding: 10px;
    border-radius: 50px;
}

.left-date-link-container {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.refresh-icon {
    margin-left: 10px;
}

/* card */

.all-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.card-container {
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 8px;
    width: 250px;
    max-height: 300px;
    margin-bottom: 15px;
}

.card-logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 15px;
}

.stats-up-container {
    background-color: #BCF4DE;
    padding: 5px 10px;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
}

.stats-up-container p {
    margin: 0;
    font-size: 14px;
}

.stats-down-container p {
    margin: 0;
    font-size: 14px;
}

.stats-down-container {
    background-color: #F13C3C33;
    padding: 5px 10px;
    border-radius: 50%;
    text-align: center;
    font-weight: 700;
}

.title-card h3 {
    font-size: 36px;
    font-weight: 700;
    margin: 2px;
    margin-right: 10px;
    margin-left: 10px;
}

.desc-card {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding-right: 40px;
    margin-right: 10px;
    margin-left: 10px;
}

.desc-card p {
    margin-top: 0;
}

.skip-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    width: 550px;
    max-width: 550px;
}

.button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

/* content-top */

.content-top {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;

}

/* CHARTS */

.container-chart {
    width: 50%;
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 40px 15px;
}


.title-chart {
    font-size: 18px;
    position: absolute;
    margin-top: 0px;
    margin-left: 10px;
}

.container-chart-vertical {

    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 50px 15px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.chart-vertical {
    align-self: center;
    width: 100%;
}

.barChartChat-container {
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 20px;
}

/* CARD CHALLENGE */

.background-card-challenge {
    max-width: 380px;
    min-width: 380px;
    object-fit: cover;
    max-height: 300px;
    min-height: 300px;
    border-radius: 20px;
}


.info-card-challenge-container {
    display: flex;
    gap: 20px;
}

.card-challenge-container h3 {
    margin-bottom: 0;
    margin-top: 5px !important;

}

.nombre-inscrit-challenge {
    padding: 3px 9px;
    background-color: #BCF4DE;
    border-radius: 5px;
}

.date-card-challenge-container {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #270D60;
    opacity: 0.4;
}

.all-card-challenge-container {
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: 600px;
    margin-bottom: 50px;
}

/* carousel */
.carousel-laptop {
    display: flex;
}

.carrousel-phone-container {
    display: none;
}

/* RANKING SCREEN */
.user-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.user-icon {
    position: relative;
    top: -120px;
    left: 30px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FDEDCB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    margin: auto;
}

.user-info-container {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #FDEDCB;
    width: 100%;
    max-width: 400px;
    text-align: center;
    padding: 15px 0;
}

.top1 {
    height: 200px;
    background-color: #F9D27E;
}

.top2 {
    height: 100px;
}

.top2-text {
    margin-top: 30px;
}

.top1 p {
    background-color: #FDEDCB !important;
}

.top1-text {
    margin-top: 130px;
}

.user-info-container h2 {
    font-size: 16px;
}

.user-info-container p {
    padding: 5px 9px;
    background-color: #F9D27E;
    border-radius: 5px;
    color: #270D60;
    max-width: 100px;
    margin: auto;
}

.top-rank-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.picture-container {
    margin-bottom: 20px;
}

.top-rank-list-container {
    margin-top: 150px;
    display: flex;
    justify-content: space-around;
    align-items: end;
}

/* Responsive styles */
@media (max-width: 768px) {
    .user-info-container {
        max-width: 100%;
        padding: 10px 0;
    }

    .top1 {
        height: 150px;
    }

    .top1-text {
        margin-top: 100px;
    }

    .top2 {
        height: 100px;
    }

    .top2-text {
        margin-top: 20px;
    }

    .user-info-container h2 {
        font-size: 14px;
    }

    .user-info-container p {
        max-width: 80px;
    }

    .top-rank-list-container {
        flex-direction: column;
        align-items: center;
    }

    .top-rank-container {
        margin-bottom: 20px;
    }
    .card-container{
        padding:5px
    }
}

@media (max-width: 480px) {
    .display-none {
        display: none!important;
    } 
    .user-info-container {
        padding: 8px 0;
    }

    .top1 {
        height: 120px;
    }

    .top1-text {
        margin-top: 90px;
    }

    .top2 {
        height: 60px;
    }

    .top2-text {
        margin-top: 15px;
    }

    .user-info-container h2 {
        font-size: 12px;
    }

    .user-info-container p {
        max-width: 60px;
    }

    .picture-container {
        margin-bottom: 15px;
    }
}

/* Liste ranking */

.rank-users-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 0px !important;
}

.info-users {
    display: flex;
    background-color: #FBF7FF;
    border-radius: 20px;
    justify-content: space-between;
}

.user-name {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 40px;
}

.users-point-container {
    padding: 5px 20px;
}

.users-point-container p {
    padding: 3px 9px;
    background-color: #F9D27E;
    border-radius: 5px;
}

.pitcure-users-list {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.row {
    display: flex;
    gap: 10px;
}

/* Large Card  */

.large-card-container {
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    margin-bottom: 50px;
    width: 95%;
    max-width: 630px;
    gap: 20px;
}

.stats-logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 15px 0px 15px;
}

.all-large-card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

}

.n-large-card {
    font-size: 36px;
    font-weight: 700;
    margin-left: 15px;
    margin-bottom: 0;
    margin-top: 0;
}

.p-large-card {
    font-size: 16px;
    font-weight: 400;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 20px;
}

/* CARD TEAMS */

.card-teams-container {
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 15px;
}

.teams-picture-contain {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
}

.team-info-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.team-info {
    display: flex;
    align-items: center;
}

.team-info img {
    margin-right: 10px;
}

.nb-message {
    display: flex;
    align-items: center;
}

.nb-message p {
    margin-left: 10px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-left: 10px;
    color: #270D60;
    background-color: #F5F1FF;
    max-width: 250px;
    padding: 5px 10px;
    border-radius: 30px;
}

.labels-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

/* ENTREPRISE SCREEN */

.header-entreprise-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 25px;
    border: 1px solid #E0CEFF;
    border-radius: 20px;
}

.header-entreprise-container p {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    color: #270D60;
}

.image-entreprise {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-right: 30px;
}


/* CARD ENTREPRISE */

.all-card-entreprise {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 50px;
}

.card-entreprise-container {
    border: 1px solid #E0CEFF;
    border-radius: 20px;
    padding: 25px 20px;
    margin-bottom: 15px;
    width: 250px;
    max-width: 300px;
}

.nb-pers {
    font-size: 36px;
    font-weight: 700;
    margin: 15px;
    margin-left: 0;
}

.nb-pers span {
    font-size: 26px;
    font-weight: 400;
    margin-left: 5px;
    opacity: 0.8;
}

/* Styles pour le menu de navigation sur téléphone */

.navbar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 250px;
    background-color: #ffff;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 0 5px rgba(0,0,0,0.5);
    z-index: 1000;
}

.navbar-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.navbar-item {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.navbar-link {
    display: block;
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
    color: #111;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.navbar-link:hover {
    background-color: #575757;
}


.navBar-phone {
    padding: 10px;
}

.nav-phone-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon-phone {
    cursor: pointer;
}

.navBar-phone {
    display: none;
    padding-top: 10px;
    box-shadow: -1px 11px 15px 5px rgba(0,0,0,0.15);
}
.nav-phone-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 30px 0 30px;
    padding-bottom: 20px;
    padding-top: 20px;
}
.img-phone{
    max-width: 60px;
    max-height: 100px;
}

.icon-phone:hover{
    scale: 1.2;
    color: #270D60;
    transition: 0.1s;
}

/* Dropdown Header */

.header-profil {
    position: relative;
}

.header-profil-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.initial-profil {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.initial-profil h3 {
    margin-left: 5px;
    font-size: 1.2rem;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
}

.logout-button {
    display: block;
    width: 100%;
    padding: 10px 20px;
    border: none;
    background-color: white;
    text-align: left;
    cursor: pointer;
}

.logout-button:hover {
    background-color: #f0f0f0;
}

.rounded-name{
    background-color: #BCF4DE ;
    padding: 6px 6px;
    border-radius: 50px;
    margin-right: 4px!important;
}



/* LOGIN SCREEN */

.container-register{
    background: linear-gradient(90deg, rgba(39,13,96,1) 0%, rgba(79,18,184,1) 17%, rgba(251,247,255,1) 94%);
    overflow: hidden!important;
  }
  .Form{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
  .register-form h2{
    margin: 0;
    font-weight: 600;
  }
  .register-form p{
    margin: 0;
  }
  .form-container{
    margin-bottom: 120px;
    margin-top: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    min-width: 400px;
    margin-top: 100px;
  }
  .register-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .register-item{
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
  }
  .input-register{
    border-radius: 5px;
    border: 1px solid #270D60;
    padding: 5px;
    min-width: 200px;
    min-height: 25px;
  }
  .register-img{
    max-width: 200px;
    margin: 0;
    padding: 0;
  }
  .register-button{
    background-color: #4F12B8;
    color: #fff;
    border-radius: 20px ;
    min-width: 200px;
    padding: 10px;
  }
  .register-button:focus{
    background-color: #FBF7FF;
    color: #4F12B8;
  }
  .padding-register{
    padding-bottom: 50px;
  }
  body.disable-scroll {
    overflow: hidden; /* Désactiver tout défilement */
  }
  .register-container-screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .register-container-screen p, a{
    color: rgba(39,13,96,0.5);
    margin: 1px;
  }

  .container-loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

.flag-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.dp-img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
}

.button-show-filter{
    background-color: #270D60;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-left: 50px;
    text-align: center; 
}

.button-show-more{
    background-color: #270D60;
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-left: 50px;
    text-align: center; 
}

.tabs{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-right: 1px solid #E0CEFF;
    height: 100%;
    
}

.container-tabs{
    margin-left: 150px;
    display: flex;
    align-items: flex-start;
    gap: 50px;
    height: 87vh; 
}

.paymentButtonNavActive{
    background-color: #F5F1FF;
    color: #270D60;
    padding: 15px 30px 15px 10px;
    margin-bottom: 10;
    cursor: pointer;
    text-align: left; 
    border: none;
    border-right: solid 5px #4F12B8;
    width: 250px;
    font-size: 14px;
}
.paymentButtonNav{
    background-color:#ffff!important;
    border: none!important;
    color: #270D60;
    padding: 15px 30px 15px 10px;
    margin-bottom: 10;
    cursor: pointer;
    text-align: left; 
    width: 250px;
    font-size: 14px;

}

.button{
    background-color: none!important;
}

.form-container-facturation{
    margin-top: 10px;
}

.form-group-facturation{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.form-group-facturation input{
    border-radius: 5px;
    background-color: #FBF7FF;
    border: 1px solid #FBF7FF;
    padding: 25px 20px 10px 10px; 
    width: 100%;
}

.form-group-facturation label{
    font-size: 14px;
    font-weight: 400;
    color: #270d606e;
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
}

.group-group-facturation{
    display: flex;
    width: 100%;
}

.tab-content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 50px;
    margin-right: 100px;
    width: 80%;
}   

.form-group-facturation select{
    border-radius: 5px;
    background-color: #FBF7FF;
    border: 1px solid #FBF7FF;
    padding: 25px 20px 10px 0px; 
    width: 103%;
}

.form-group-facturation input:focus, 
.form-group-facturation input:focus-visible {
    outline: none; 
    border: 1px solid #FBF7FF; 
}
.form-footer-facturation{
    display: flex;  
    justify-content: flex-end;
    margin-right: -25px;
}
.form-footer-facturation button {
    background-color: #4F12B8;
    color: #fff;
    border-radius: 25px;
    padding: 15px 20px;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
    text-align: center; 
    border: none;
}

.link-payment {
    text-decoration: none;
    color: #F13C3C;
}

.paiment-error{
    color: #F13C3C;
    margin-left: 50px;
}
.paiment-error p{
    color: #F13C3C;
}
.paiment-pending{
    padding: 5px 20px;
    border: 1px solid #F13C3C;
    border-radius: 15px;
    margin-right: 50px;
}
.paiment-bulles-notification{
    padding: 5px 10px;
    background-color: #4F12B8;
    color: #fff;
    border-radius: 50px;
    margin-left: 15px;
}

.button-payment-default{
    background-color: #fff;
    color: #270D60;
    padding: 15px 10px 15px 10px;
    border-radius: 25px;
    cursor: pointer;
    text-align: center; 
    border: none;
    font-size: 14px;
    border: 1px solid #4F12B8;
}




.container-dashboard {
    @apply min-h-screen bg-[#F5F1FF] p-8;
  }
  
  /* Week Selector */
  .week-selector {
    @apply flex flex-col gap-4 mb-8;
  }
  
  .week-buttons {
    @apply inline-flex gap-2 bg-white rounded-lg p-1.5 shadow-sm;
  }
  
  .week-button {
    @apply px-4 py-2 rounded-md transition-colors text-sm font-medium min-w-[48px];
  }
  
  .week-button-active {
    @apply bg-[#270D60] text-white;
  }
  
  .week-button-inactive {
    @apply text-[#270D60] hover:bg-[#F5F1FF];
  }
  
  /* Grid Layout */
  .kpi-grid {
    @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6;
  }
  
  /* Card Styles */
  .card-container {
    @apply bg-white rounded-xl p-6 border border-[#E0CEFF] transition-all hover:shadow-lg relative h-full;
  }
  
  .card-logo-container {
    @apply flex items-center justify-between mb-6;
  }
  
  /* Evolution Stats */
  .stats-evolution {
    @apply px-3 py-1.5 rounded-full text-sm flex items-center gap-1.5;
  }
  
  .stats-up {
    @apply bg-[#BCF4DE] text-[#0E4F2C];
  }
  
  .stats-down {
    @apply bg-[#F13C3C33] text-[#F13C3C];
  }
  
  /* Card Content */
  .title-card {
    @apply mb-2;
  }
  
  .title-card h3 {
    @apply text-4xl font-bold text-[#270D60];
  }
  
  .desc-card p {
    @apply text-sm text-[#270D60] opacity-80;
  }
  
  /* Chart Container */
  .chart-container {
    @apply bg-white rounded-xl p-6 border border-[#E0CEFF] shadow-sm;
  }
  
  .chart-wrapper {
    @apply h-[300px] w-full;
  }
  
  /* Stats Container */
  .stats-container {
    @apply bg-white rounded-xl p-6 border border-[#E0CEFF] shadow-sm space-y-4;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .week-selector {
      @apply flex-col items-start;
    }
    
    .week-buttons {
      @apply w-full overflow-x-auto;
    }
    
    .kpi-grid {
      @apply grid-cols-1;
    }
  }