.buy-screen {
  font-family: Arial, sans-serif;
}

.buy-screen h2 {
  color: #270D60;
  font-size: 16px;
}

table {
  width: 100%;
  border-collapse: separate; 
  border-spacing: 0 20px;
  margin-bottom: 20px;
}

table th:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

table th:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

table td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

table td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

table th, table td {
  padding: 20px 15px;
  text-align: left;
}

table th {
  background-color: transparent;
  color: #270D60;
  font-weight: bold;
  font-size: 14px;
  padding: 15px;
}

table tbody tr {
  background-color: #FBF7FF;
}


table td {
  color: #270D60;
  font-size: 14px;
  font-weight: 500;
}

.status-badge.to-pay {
  background-color: #4F12B8;
  color: white;
}

.status-badge.paid {
  background-color: #CFFAEA;
  color: #1D8161;
}

.action-button {
  background-color: #FBF7FF;
  color: #4F12B8;
  padding: 8px 12px;
  border: 1px solid #4F12B8;
  border-radius: 8px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.action-button:hover {
  background-color: #4F12B8;
  color: #ffffff;
}

.payment-footer {
  text-align: center;
  color: #666;
  font-size: 14px;
}

.status-pending {
  display: inline-block;
  margin: 0;
  padding: 8px 10px;
  background-color: #4F12B8;
  color: #ffffff;
  border-radius: 5px;
}

.status-validate {
  display: inline-block;
  margin: 0;
  padding: 8px 10px;
  background-color: #BCF4DE;
  color: #270D60;
  border-radius: 5px;
}


.status-error {
  display: inline-block;
  margin: 0;
  padding: 8px 10px;
  background-color: #F13C3C;
  color: #ffffff;
  border-radius: 5px;
}

.status-processing {
  display: inline-block;
  margin: 0;
  padding: 8px 10px;
  background-color: #FFC107; 
  color: #ffff; 
  border-radius: 5px;
}

.status-cancelled {
  display: inline-block;
  margin: 0;
  padding: 8px 10px;
  background-color: #B0BEC5; 
  color: #270D60; 
  border-radius: 5px;
}

