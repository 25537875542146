.container-form {
  width: 80%!important;
  margin: 0 auto;    /* Centre le conteneur */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.add-payment-method {
  margin-top: 20px;
}

.payment-method-list {
  list-style: none;
  padding: 0;
}

.payment-method-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-method-details {
  display: flex;
  flex-direction: column;
}

.payment-method-actions button {
  margin-left: 10px;
  background-color: #0570de;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.payment-method-actions button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.submit-button {
  background-color: #0570de; /* Couleur de fond */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.submit-button:disabled {
  background-color: #cccccc; /* Couleur de fond grisée pour le bouton désactivé */
  color: #666666; /* Couleur du texte plus sombre */
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}
.button-payment-default{
  background-color: #fff;
  color: #4F12B8;
  padding: 10px 10px 10px 10px;
  border-radius: 25px;
  cursor: pointer;
  text-align: center; 
  border: none;
  font-size: 14px;
  border: 1px solid #4F12B8;
  margin-right: 15px;
}

.button-payment-supprimer{
  background-color: #ffff;
  color: #F13C3C;
  padding: 10px 10px 10px 10px;
  border-radius: 25px;
  cursor: pointer;
  text-align: center; 
  border: none;
  font-size: 14px;
  border: 1px solid #F13C3C;
}

.button-payment-disable{
  background-color: #fff;
  color: #cccccc;
  padding: 10px 10px 10px 10px;
  border-radius: 25px;
  cursor: not-allowed;
  text-align: center; 
  border: none;
  font-size: 14px;
  border: 1px solid #cccccc;
  margin-right: 15px;

}
